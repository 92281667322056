import React from "react"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

import { SpecialTitle } from "../typography"
import { Button, Spacer } from "../elements"
import { ArrowRightIcon } from "../icons"

export function HeaderSecondary({ fluid, title, caption, smalltitle }) {
  return (
    <>
      <Cover>
        <StyledBackground fluid={fluid}>
          <ContentCover>
            <SpecialTitle>TelecomHub</SpecialTitle>
            <HeaderTitle color={props => props.theme.color.white}>
              {title}
              <br />
              {smalltitle}
            </HeaderTitle>
            <SpecialTitle color={props => props.theme.color.white}>
              {caption}
            </SpecialTitle>
            <Spacer margin="1rem" />
            <Button
              to="/contact/"
              btncolor={props => props.theme.color.navyblue}
              color={props => props.theme.color.white}
            >
              Contact us <ArrowWhite />
            </Button>
          </ContentCover>
        </StyledBackground>
      </Cover>
    </>
  )
}

const Cover = styled.section`
  width: 100%;
  height: 65vh;
`

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`
const ArrowWhite = styled(ArrowRightIcon)`
  color: ${props => props.theme.color.highlight};
`

const ContentCover = styled.div`
  position: absolute;
  left: 10vw;
  top: 20vh;
  max-width: 45vw;
  @media only screen and (max-width: 75em) {
    left: 10vw;
    bottom: 25vh;
    max-width: 75vw;
  }
  @media only screen and (max-width: 56.25em) {
    left: 10vw;
    bottom: 25vh;
    max-width: 75vw;
  }
  @media only screen and (max-width: 37.5em) {
    left: 5vw;
    top: 20vh;
    max-width: 85vw;
  }
  @media only screen and (min-width: 112.5em) {
    bottom: 40vh;
    max-width: 55vw;
  }
`

const HeaderTitle = styled.h1`
  font-weight: 700;
  font-size: 6rem;
  letter-spacing: 0.4rem;
  line-height: 1.1;
  color: ${props => props.color || props.color.white};
  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 75em) {
    font-size: 10rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 10rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 4.3rem;
  }
`
