import React from "react"
import styled from "styled-components"
import { Title, SpecialTitle, Caption } from "../typography"
import { MailBigIcon, CallBigIcon } from "../icons"

export function ContactSection() {
  return (
    <Cover>
      <TitleCover>
        <Title color={props => props.theme.color.white}>
          Talk to our expert team about your requirements
        </Title>
      </TitleCover>
      <Wrapper>
        <EmailCover href="mailto:info@telecomhub.com.au">
          <MailBigIcon />
          <ContentCover>
            <SpecialTitle color={props => props.theme.color.white}>
              info@telecomhub.com.au
            </SpecialTitle>
            <Caption color={props => props.theme.color.white}>
              Have a question, want to learn more? Email us your enquiry and a
              empower expert will get in touch.
            </Caption>
          </ContentCover>
        </EmailCover>
        <PhoneCover href="tel:+61394577300">
          <CallBigIcon />
          <ContentCover>
            <SpecialTitle color={props => props.theme.color.white}>
              call 03 9457 7300
            </SpecialTitle>
            <Caption color={props => props.theme.color.white}>
              Speak with one of our empower experts on 03 9457 7300 between 9am
              to 5:30pm, Monday to Friday.
            </Caption>
          </ContentCover>
        </PhoneCover>
      </Wrapper>
    </Cover>
  )
}

const Cover = styled.section`
  margin: 0.5rem auto 0 auto;
  background-color: ${props => props.theme.color.navyblue};
  padding: 5rem 1.5rem;
`

const TitleCover = styled.div`
  text-align: center;
  padding: 1rem;
`

const Wrapper = styled.div`
  display: flex;

  @media screen and (max-width: 37.5em) {
    display: block;
  }
`

const EmailCover = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 2rem 5rem;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    padding: 2rem 0rem;
  }
  @media only screen and (min-width: 112.5em) {
    padding: 5rem 20rem;
  }
`
const PhoneCover = styled(EmailCover)``

const ContentCover = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`
