import React from "react"
import styled from "styled-components"
import { SpecialParagraph, SpeacialMediumTitle } from "../typography"
import { Link } from "gatsby"

import ICTHUBLIGHT from "../../images/icthub-light.png"

export function Footer() {
  return (
    <Cover>
      <FooterSection>
        <ContactCover>
          <Link style={{ textDecoration: "none" }} to="/">
            <Logo src={ICTHUBLIGHT}></Logo>
          </Link>
          <SpeacialMediumTitle color={props => props.theme.color.highlight}>
            Sales & Enquiries
          </SpeacialMediumTitle>
          <Link style={{ textDecoration: "none" }} href="tel:+61394577300">
            <SpecialParagraph>03 9457 7300</SpecialParagraph>
          </Link>
          <SpeacialMediumTitle color={props => props.theme.color.highlight}>
            Email
          </SpeacialMediumTitle>
          <Link
            style={{ textDecoration: "none" }}
            href="mailto:info@telecomhub.com.au"
          >
            <SpecialParagraph>info@telecomhub.com.au</SpecialParagraph>
          </Link>
          <SpeacialMediumTitle color={props => props.theme.color.highlight}>
            Address
          </SpeacialMediumTitle>
          <Link style={{ textDecoration: "none" }}>
            <SpecialParagraph>
              Level 1, 80-82 Bell Street, Heidelberg Heights, VIC 3081
            </SpecialParagraph>
          </Link>
          <SocialContainer></SocialContainer>
        </ContactCover>
        <FooterNav>
          <ServiceLinks>
            <SpeacialMediumTitle color={props => props.theme.color.highlight}>
              Solutions
            </SpeacialMediumTitle>
            <NavLink to="/mobility/">Mobility</NavLink>
            <NavLink to="/voice/">Voice</NavLink>
            <NavLink to="/data/">Data</NavLink>
            <NavLink to="/collabration/">Collabration</NavLink>
          </ServiceLinks>

          <InformationLinks>
            <SpeacialMediumTitle color={props => props.theme.color.highlight}>
              Quick Links
            </SpeacialMediumTitle>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about-us/">About us</NavLink>
            <NavLink to="/contact/">Contact</NavLink>
          </InformationLinks>
        </FooterNav>
      </FooterSection>
      <CopyRightSection></CopyRightSection>
    </Cover>
  )
}
const Cover = styled.footer`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.color.navyblue};
`

const CopyRightSection = styled.div`
  width: 100%;
  height: 3rem;
  background-color: ${props => props.theme.color.highlight};
`
const FooterSection = styled.div`
  padding: 3rem 1rem;
  width: 70%;
  margin: 0.5rem auto 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: center; */

  @media only screen and (max-width: 75em) {
    width: 80%;
    margin: 0.5rem auto 0 auto;
  }
  @media only screen and (max-width: 56.25em) {
    width: 80%;
    margin: 0.5rem auto 0 auto;
  }
  @media only screen and (max-width: 37.5em) {
    width: 99%;
    margin: 0.5rem auto 0 auto;
    padding: 3rem 2rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const ContactCover = styled.div`
  flex: 1;
`
const SocialContainer = styled.div``

const FooterNav = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ServiceLinks = styled.div`
  display: flex;
  flex-direction: column;
`

const InformationLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const NavLink = styled(Link)`
  padding: 0.1rem 1rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.theme.color.white};
  /* border-bottom-width: 0.021rem;
  border-bottom-color: ${props =>
    props.theme.color.white};
  border-bottom-style: solid; */
`

const Logo = styled.img`
  width: 40rem;
  height: auto;
  align-self: flex-start;
  @media only screen and (max-width: 37.5em) {
    width: 30rem;
  }
`
