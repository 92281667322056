import React, { useState, useEffect } from "react"
import styled from "styled-components"

import IcthubLogoLight from "../../images/icthub-light.png"
import IcthubLogoDark from "../../images/icthub.png"
import { Link } from "gatsby"
import { Menu, X, ChevronDown, Phone } from "react-feather"

export function Navbar() {
  const [toggle, setToggle] = useState("hidden")
  const [menuShadow, setMenuShadow] = useState("none")
  const [logo, setLogo] = useState(IcthubLogoLight)
  const [menuBackground, setMenuBackground] = useState("none")
  const [linkColor, setLinkColor] = useState("#ffffff")
  const [visibleSolutions, setVisibleSolution] = useState("hidden")
  const [visibleItServices, setVisibleItServices] = useState("hidden")

  function onHoverSolutions() {
    setVisibleItServices("hidden")
    setVisibleSolution("visible")
  }
  function onHoverIt() {
    setVisibleSolution("hidden")
    setVisibleItServices("visible")
  }

  function onHoverOut() {
    if (visibleItServices === "visible") {
      return setVisibleItServices("hidden")
    } else if (visibleSolutions === "visible") {
      return setVisibleSolution("hidden")
    }
  }

  function scrollMenu() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setLogo(IcthubLogoDark)
      setLinkColor("#000000")
      setMenuBackground("#ffffff")
      setMenuShadow("drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))")
    } else {
      setLogo(IcthubLogoLight)
      setLinkColor("#ffffff")
      setMenuBackground("none")
      setMenuShadow("none")
    }
  }
  function menuToggle() {
    setToggle("visible")
  }
  function menuClose() {
    setToggle("hidden")
  }

  useEffect(() => {
    window.onscroll = function () {
      scrollMenu()
    }
    // const module = typeof window !== `undefined` ? require("module") : null
  })
  return (
    <>
      <Cover>
        <Navigation menuBackground={menuBackground} menuShadow={menuShadow}>
          <Link style={{ textDecoration: "none" }} to="/">
            <Logo src={logo} alt="Icthub Pty Ltd" />
          </Link>
          <Navlinks>
            <Unordered>
              <ListItem onMouseOver={onHoverOut}>
                <LinkText LinkColor={linkColor} to="/">
                  Home
                </LinkText>
              </ListItem>
              <ListItem>
                <LinkText onMouseOver={onHoverSolutions} LinkColor={linkColor}>
                  Solutions
                  <ChevronDown />
                </LinkText>
                <HoverLinks visibleSolutions={visibleSolutions}>
                  <LinkText
                    LinkColor={props => props.theme.color.navyblue}
                    to="/mobility/"
                  >
                    Mobility
                  </LinkText>
                  <LinkText
                    LinkColor={props => props.theme.color.navyblue}
                    to="/voice/"
                  >
                    Voice
                  </LinkText>
                  <LinkText
                    LinkColor={props => props.theme.color.navyblue}
                    to="/data/"
                  >
                    Data
                  </LinkText>
                  <LinkText
                    LinkColor={props => props.theme.color.navyblue}
                    to="/collabration/"
                  >
                    Unified Communication
                  </LinkText>
                </HoverLinks>
              </ListItem>
              <ListItem>
                <LinkText
                  onMouseOver={onHoverIt}
                  LinkColor={linkColor}
                  href="https://icthub.com.au/"
                >
                  It Services
                </LinkText>
              </ListItem>
              <ListItem>
                <LinkText
                  onMouseOver={onHoverIt}
                  LinkColor={linkColor}
                  href="https://edigitalhub.com.au/"
                >
                  Digital Services
                </LinkText>
              </ListItem>
              <ListItem onMouseOut={onHoverOut}>
                <LinkText LinkColor={linkColor} to="/about-us/">
                  About Us
                </LinkText>
              </ListItem>

              <ListItem onMouseOut={onHoverOut}>
                <LinkText LinkColor={linkColor} to="/contact/">
                  Contact
                </LinkText>
              </ListItem>

              <ListItem onMouseOut={onHoverOut}>
                <LinkText LinkColor={linkColor} href="tel:+61394577300">
                  <PhoneCallIcon />
                  03 9457 7300
                </LinkText>
              </ListItem>
            </Unordered>
          </Navlinks>

          <MenuIcon ColorChoice={linkColor} onClick={menuToggle} src={Menu} />
        </Navigation>

        <MobileNav display={toggle}>
          <MenuClose onClick={menuClose} src={X} />

          <MobileLinkCover onClick={menuClose}>
            <MobileNavLink to="/">Home</MobileNavLink>
            <MobileNavLink to="/about-us/"> About Us</MobileNavLink>
            <MobileNavLink to="/contact/">Contact</MobileNavLink>
            <MobileNavLink to="/mobility/"> Mobility</MobileNavLink>
            <MobileNavLink to="/data/">Data</MobileNavLink>
            <MobileNavLink to="/voice/">Voice</MobileNavLink>
            <MobileNavLink to="/collabration/">Collabration</MobileNavLink>
            <MobileNavLink href="https://edigitalhub.com.au/">
              Digital Services
            </MobileNavLink>
            <MobileNavLink href="https://icthub.com.au/">
              IT Services
            </MobileNavLink>
          </MobileLinkCover>
        </MobileNav>
      </Cover>
    </>
  )
}

const MobileLinkCover = styled.div`
  padding: 6rem 2.5rem;
  display: flex;
  flex-direction: column;
`
const MobileNavLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: 0.4rem;
  line-height: 1.7;
  color: ${props => props.theme.color.offwhite};
  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);
`

const HoverLinks = styled.div`
  width: auto;
  height: auto;
  min-width: 15rem;
  padding: 1rem 1rem;
  margin-top: 3rem;
  background-color: ${props => props.theme.color.offwhite};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  visibility: ${props => props.visibleSolutions};
  visibility: ${props => props.visibleItServices};
  :hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
  }
`

const PhoneCallIcon = styled(Phone)`
  margin: 0 0.8rem;
  width: 2rem;
  height: 2rem;
`

const Cover = styled.header`
  width: 100%;
  height: auto;
`

const Navigation = styled.section`
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: auto;
  padding: 1.5rem 1.5rem;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.menuBackground};
  filter: ${props => props.menuShadow};
`

const Logo = styled.img`
  width: 35rem;
  height: auto;
  align-self: flex-start;
  @media only screen and (max-width: 37.5em) {
    width: 30rem;
  }
`

const Navlinks = styled.nav`
  /* display: none; */
  @media only screen and (max-width: 75em) {
    display: none;
  }
  @media only screen and (max-width: 56.25em) {
    display: none;
  }
  @media only screen and (max-width: 37.5em) {
    display: none;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const Unordered = styled.ul`
  display: flex;
  justify-content: space-between;
`

const ListItem = styled.li`
  padding: 0 1rem;
`

const LinkText = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0.3rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${props => props.LinkColor};
  &:hover {
    color: ${props => props.theme.color.highlight};
  }
`

const MobileNav = styled.section`
  position: fixed;
  z-index: 100000;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  background: ${props => props.theme.color.navyblue};
  @media only screen and (max-width: 75em) {
    visibility: ${props => props.display};
    width: 50%;
    height: 100vh;
    top: 0;
    right: 0;
  }
  @media only screen and (max-width: 56.25em) {
    visibility: ${props => props.display};
    width: 60%;
    height: 70vh;
    top: 0;
    right: 0;
  }
  @media only screen and (max-width: 37.5em) {
    visibility: ${props => props.display};
    width: 100%;
    height: 100vh;
  }
`

const MenuIcon = styled(Menu)`
  width: 4rem;
  height: 4rem;
  color: ${props => props.ColorChoice};
  display: none;
  @media only screen and (max-width: 75em) {
    display: block;
  }
  @media only screen and (max-width: 56.25em) {
    display: block;
  }
  @media only screen and (max-width: 37.5em) {
    display: block;
  }
`
const MenuClose = styled(X)`
  width: 4rem;
  height: 4rem;
  position: inherit;
  color: ${props => props.theme.color.highlight};
  top: 1rem;
  right: 1rem;
`
