import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export function Button({ children, to, btncolor, color, onClick }) {
  return (
    <Cover onClick={onClick}>
      <ButtonBody onClick={onClick} to={to} btncolor={btncolor} color={color}>
        {children}
      </ButtonBody>
    </Cover>
  )
}
const Cover = styled.div``
const ButtonBody = styled(Link)`
  display: inline-block;
  cursor: pointer;
  background-color: ${props => props.btncolor || props.theme.color.navyblue};
  border-radius: 0.5rem;
  color: ${props => props.color || props.theme.color.white};
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 1rem 1rem 1rem 2rem;
  text-decoration: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.btnshadow};
    transform: opacity 0.5s scale(1.01);
  }
`
