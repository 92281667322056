import styled from "styled-components"

import React from "react"

export function Title({ children, color }) {
  return <Text color={color}>{children}</Text>
}

const Text = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: ${props => props.color || props.theme.color.navyblue};
  margin-top: 0.5rem;
`
