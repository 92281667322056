import React from "react"
import styled, { keyframes } from "styled-components"

import { Paragraph } from "../typography"
import { Link } from "gatsby"

import { Spacer } from "./spacer"

export function ServiceCard({
  id,
  bgColor,
  className,
  title,
  to,
  src,
  bgImage,
  order,
  details,
}) {
  return (
    <Cover bgColor={bgColor} className={className} id={id}>
      <Wrapper>
        <ImageCover order={order}>
          <ImageContainer src={bgImage}></ImageContainer>
          <Overlay />
        </ImageCover>
        <ContentCover>
          <FlexWrapper>
            {src && <LinkIcon src={src} />}
            <Spacer margin="1rem" />
            <StrongTitle>{title}</StrongTitle>
          </FlexWrapper>
          <Paragraph>{details}</Paragraph>
          <Spacer margin="2rem" />
          {to && <ReadMore to={to}>Find out more</ReadMore>}
        </ContentCover>
      </Wrapper>
    </Cover>
  )
}

const StrongTitle = styled.h1`
  font-size: 5rem;
  font-weight: 800;
  color: ${props => props.theme.color.navyblue};
  margin-top: 1rem;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 3.5rem;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const Cover = styled.section`
  width: 100%;
  height: auto;
  padding: 4rem 2rem;
  background-color: ${props => props.bgColor || props.theme.color.white};
`
const Wrapper = styled.div`
  width: 95%;
  height: auto;
  margin: 3rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    margin: 1rem auto;
  }
`
const ImageCover = styled.div`
  order: ${props => props.order};
  position: relative;
  width: 45%;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
  margin: 2rem 0;
  box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: 100%;
    padding: 0;
    order: 1;
  }
  @media only screen and (min-width: 112.5em) {
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(42, 219, 239, 0.35) 100%
  );
`

const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  background-size: cover;
`
const ContentCover = styled.div`
  width: 40%;
  height: 100%;
  @media only screen and (max-width: 75em) {
  }
  @media only screen and (max-width: 56.25em) {
  }
  @media only screen and (max-width: 37.5em) {
    width: 100%;
    height: 100%;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const moving = keyframes`
  from{
    transform:translateY(1.5rem);
    transform:scale(1.2);
    
  }

`

const FlexWrapper = styled.div``
const LinkIcon = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  &:hover {
    animation: ${moving} 2s linear alternate infinite;
  }
  @media only screen and (max-width: 37.5em) {
    width: 7.5rem;
    height: 7.5rem;
    margin: 0 2rem 0 2rem;
  }
`
const ReadMore = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.highlight};
  font-size: 2.2rem;
  font-weight: 700;
`
