import React from "react"
import styled from "styled-components"

export function Spacer({ margin }) {
  return <SpaceSpan margin={margin} />
}

const SpaceSpan = styled.span`
  display: block;
  margin-top: ${props => props.margin};
  margin-bottom: ${props => props.margin};
`
