import styled from "styled-components"
import {
  PhoneCall,
  ArrowRight,
  Mail,
  User,
  X,
  Tag,
  CheckSquare,
  LogOut,
  HelpCircle,
} from "react-feather"

export const Help = styled(HelpCircle)`
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(180deg);
  color: ${({ theme }) => theme.color.highlight};
  margin: 0 0 1rem 0;
`
export const UserIcon = styled(User)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.color.highlight};
  margin: 0 0 1rem 0;
`
export const CallIcon = styled(PhoneCall)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.color.highlight};
  margin: 0 0 0 1rem;
`

export const LogoutIcon = styled(LogOut)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.color.highlight};
`
export const TagIcon = styled(Tag)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.color.highlight};
`
export const CheckIcon = styled(CheckSquare)`
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.color.highlight};
`

export const ArrowRightIcon = styled(ArrowRight)`
  width: 3rem;
  height: 3rem;
  stroke-width: 2.5px;
  stroke-linecap: round;
  stroke-linejoin: round;
  color: ${({ theme }) => theme.color.highlight};
  margin: 0 0 0 1rem;
`

export const CallBigIcon = styled(PhoneCall)`
  width: 6.5rem;
  height: 6.5rem;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  flex: 1.5;
  color: ${({ theme }) => theme.color.highlight};
`
export const MailBigIcon = styled(Mail)`
  width: 6.5rem;
  height: 6.5rem;
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  flex: 1.5;
  color: ${({ theme }) => theme.color.highlight};
`
export const CloseIcon = styled(X)`
  width: 5rem;
  height: 5rem;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  color: ${({ theme }) => theme.color.highlight};
`
