import React from "react"
import styled from "styled-components"

export function SpecialParagraph({ children, color }) {
  return <Text color={color}>{children}</Text>
}

const Text = styled.p`
  font-size: 1.7rem;
  font-weight: 300;
  color: ${props => props.color || props.theme.color.white};
  margin: 0.5rem 0;
  line-height: 1.5;
  text-decoration: none;
`
