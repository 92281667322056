import React from "react"
import styled from "styled-components"

export function SpecialTitle({ color, children }) {
  return <Title color={color}>{children}</Title>
}

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.color || props.theme.color.highlight};
`
