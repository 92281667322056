import React from "react"
import styled from "styled-components"

import huntgroup from "../../images/unified/huntgroup.png"
import vpoicemailtomail from "../../images/unified/vpoicemailtomail.png"
import simulring from "../../images/unified/simulring.png"
import sequring from "../../images/unified/sequring.png"
import calltransfer from "../../images/unified/calltransfer.png"
import autoresponse from "../../images/unified/autoresponse.png"
import voice from "../../images/icons/voice.svg"

import { ServiceCard } from "../elements"

import ProductMobility from "../../images/product/mobilityProduct.jpg"
import ProductVoice from "../../images/product/voiceProduct.jpg"
import ProductData from "../../images/product/dataProduct.jpg"
import ProductCollabration from "../../images/product/collaborationProduct.jpg"

import { Paragraph } from "../typography"

export function CollabrationSection() {
  return (
    <>
      <Cover>
        <Paragraph>
          {/* We’re committed to being your partner for the long haul. Caring for
          your business with ICT solutions and support that are customised to
          your individual needs. It’s our personal approach that makes all the
          difference. Your success is our success. */}
        </Paragraph>
      </Cover>
      <ServiceCard
        id="serviceMobility"
        title="Hunt Group"
        details="Distribute incoming calls between your available lines."
        src={huntgroup}
        bgImage={ProductMobility}
      />
      <ServiceCard
        order="2"
        id="serviceVoice"
        title="Sequential Ring"
        details="Specified phones will ring one after the other until the call is answered"
        src={sequring}
        bgImage={ProductVoice}
      />
      <ServiceCard
        id="serviceData"
        title="Call Transfer & Hold"
        details="Place calls on hold or transfer them to other users."
        src={calltransfer}
        bgImage={ProductData}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Simultaneous Ring"
        details="Have incoming calls ring on your Commander Phone and your mobile at the same time."
        src={simulring}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Voicemail to Email"
        details="Have voicemails sent to your email  for convenience and storage."
        src={vpoicemailtomail}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Auto Receptionist"
        details="Answers and directs calls to a relevant person based on your setup (Press 1 for sales etc.)"
        src={autoresponse}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Music on Hold"
        details="Keep all your callers engage with music while on hold and prevent them all from hanging up calls with exclusive music on hold feature."
        src={voice}
        bgImage={ProductCollabration}
      />
    </>
  )
}

const Cover = styled.div`
  background-color: ${props => props.theme.color.white};
  text-align: center;
  padding: 5rem 25vw;

  @media only screen and (max-width: 37.5em) {
    padding: 3rem 7vw;
  }
`
