import React from "react"
import styled from "styled-components"

import Mobility1 from "../../images/icons/mobility1.svg"

import network from "../../images/icons/network.svg"

import collabration from "../../images/icons/collabration.svg"
import managed from "../../images/icons/managed.svg"
import mobility from "../../images/icons/mobility.svg"
import voice from "../../images/icons/voice.svg"

import { SpeacialMediumTitle } from "../typography"
import { Link } from "gatsby"

export function HeaderServicesLinks({ id, onClick }) {
  function scrollService(e) {
    document.getElementById(e).scrollIntoView(false)
  }

  return (
    <Cover id={id}>
      <LinkCover onClick={e => scrollService("serviceMobility")}>
        <LinkIcon src={Mobility1} />
        <SpeacialMediumTitle>Mobility</SpeacialMediumTitle>
      </LinkCover>
      <LinkCover onClick={e => scrollService("serviceVoice")}>
        <LinkIcon src={voice} />
        <SpeacialMediumTitle>Voice</SpeacialMediumTitle>
      </LinkCover>
      <LinkCover onClick={e => scrollService("serviceData")}>
        <LinkIcon src={mobility} />
        <SpeacialMediumTitle>Data or Internet</SpeacialMediumTitle>
      </LinkCover>
      <LinkCover onClick={e => scrollService("serviceCollabration")}>
        <LinkIcon src={collabration} />
        <SpeacialMediumTitle>Unified Communication</SpeacialMediumTitle>
      </LinkCover>
      <TagIcon style={{ textDecoration: "none" }} href="https://icthub.com.au/">
        <LinkCover>
          <LinkIcon src={network} />
          <SpeacialMediumTitle>IT Services</SpeacialMediumTitle>
        </LinkCover>
      </TagIcon>
      <TagIcon
        style={{ textDecoration: "none" }}
        href="https://edigitalhub.com.au/"
      >
        <LinkCover>
          <LinkIcon src={managed} />
          <SpeacialMediumTitle>Digital Services</SpeacialMediumTitle>
        </LinkCover>
      </TagIcon>
    </Cover>
  )
}

const Cover = styled.section`
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: relative;
  left: 0;
  bottom: 0;
  background-color: ${props => props.theme.color.navyblue};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem;
  @media only screen and (max-width: 75em) {
    position: relative;
    margin: 0.5rem auto;
  }
  @media only screen and (max-width: 56.25em) {
    position: relative;
    margin: 0.5rem auto;
  }
  @media only screen and (max-width: 37.5em) {
    background-color: ${props => props.theme.color.navyblue};
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
    margin: 0.5rem auto;
  }
  @media only screen and (min-width: 112.5em) {
  }
`

const LinkCover = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 75em) {
    justify-content: flex-start;
    padding: 1rem 0;
    width: 50%;
  }
  @media only screen and (max-width: 56.25em) {
    justify-content: flex-start;
    padding: 1rem 0;
    width: 50%;
  }
  @media only screen and (max-width: 37.5em) {
    justify-content: flex-start;
    padding: 1rem 0;
    width: 100%;
  }
`

const LinkIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 1rem 0 1rem;
  @media only screen and (max-width: 37.5em) {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 2rem 0 2rem;
  }
`

const TagIcon = styled(Link)``
