import React, { useState } from "react"
import styled from "styled-components"

import { Link } from "gatsby"
import {
  Help,
  CloseIcon,
  LogoutIcon,
  TagIcon,
  CheckIcon,
  UserIcon,
} from "../icons"
import { Title } from "../typography"

export function LoginButton() {
  const [popOptions, setPopOptions] = useState("hidden")

  function onHoverFunc() {
    setPopOptions("visible")
  }
  function onHoverOutFunc() {
    setPopOptions("hidden")
  }

  return (
    <>
      <Cover to="" onClick={onHoverFunc}>
        <LoginCover>
          <HelpWhiteIcon />
          Support
        </LoginCover>
      </Cover>

      <PopUp pop={popOptions}>
        <CloseCover>
          <Title>Login Resources</Title>
          <CloseIcon onClick={onHoverOutFunc} />
        </CloseCover>
        <PopupContent>
          <BigButton to="/">
            <TagIcon />
            Acount
          </BigButton>
          <BigButton to="/">
            <UserIcon />
            Customer
          </BigButton>
          <BigButton to="/">
            <CheckIcon />
            Usage Statics
          </BigButton>
          <BigButton to="/">
            <LogoutIcon />
            Client Portal
          </BigButton>
        </PopupContent>
      </PopUp>
    </>
  )
}

const HelpWhiteIcon = styled(Help)`
  color: ${props => props.theme.color.white};
`

const Cover = styled.div`
  writing-mode: vertical-lr;
  cursor: pointer;
`
const LoginCover = styled(Link)`
  position: fixed;
  right: 0;
  top: 40vh;
  transform: rotate(180deg);
  text-align: center;
  background-color: ${props => props.btncolor || props.theme.color.highlight};
  border-radius: 0 0.5rem 0.5rem 0;
  color: ${props => props.color || props.theme.color.white};
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 1rem 1rem;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 75em) {
    right: 0;
    top: 30vh;
  }
  @media only screen and (max-width: 56.25em) {
    right: 0;
    top: 30vh;
  }
  @media only screen and (max-width: 37.5em) {
    right: 0;
    top: 25vh;
  }
  @media only screen and (min-width: 112.5em) {
    right: 0;
    top: 40vh;
  }
`

const PopUp = styled.div`
  visibility: ${props => props.pop};
  background-color: ${props => props.theme.color.white};
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 1rem 1rem 0px 0px;
  position: fixed;
  z-index: 3000;
  width: 100%;
  height: auto;
  bottom: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const CloseCover = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
`
const PopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const BigButton = styled(Link)`
  background-color: ${props => props.theme.color.navyblue};
  width: 15rem;
  min-height: 15rem;
  padding: 2rem;
  margin: 1rem;
  border-radius: 1rem;
  color: ${props => props.theme.color.white};
  font-size: 2.2rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
