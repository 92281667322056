import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const theme = {
  font: {
    normal: "Montserrat, sans-serif",
    heading: "Raleway, sans-serif",
  },
  color: {
    navyblue: "#000000",
    navyblueLigt: "rgba(1,17,117,0.95)",
    darkLight: "rgba(0,0,0,0.5)",
    highlight: "#2ADBEF",
    dark: "#141414",
    offwhite: "#FDFDFF",
    menuWhite: "rgba(255,255,255,0.95)",
    white: "#FFFFFF",
    border: "rgba(164,162,162,1)",
  },
  shadow: {
    btnshadow: "0px 5px 20px rgba(0, 0, 0, 0.25)",
  },
}

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: ${({ theme }) => theme.font.normal};
    font-size: 62.5%;
    color: ${({ theme }) => theme.color.dark};
    @media only screen and (max-width: 75em) {
      font-size: 62%;
    }
    @media only screen and (max-width: 56.25em) {
      font-size: 62%;
    } 
    @media only screen and (max-width: 37.5em) {
      font-size: 55%;
    } 
     @media only screen and (min-width: 112.5em) {
      font-size: 65%;
    } 
  }
  body {
    line-height: 1.5;
    letter-spacing: 0;
   
  }
  .container{
    background-color:${({ theme }) => theme.color.highlight};
  }
  h1{
    font-family: ${({ theme }) => theme.font.heading};
    letter-spacing:1px;
    line-height:1.5;
  }

  p{
    font-family: ${({ theme }) => theme.font.normal};
    letter-spacing:1px;
    line-height:1.23;
  }
  a{
    font-family: ${({ theme }) => theme.font.heading};
    letter-spacing:1.5px;
    &:active{
      color:${({ theme }) => theme.color.highlight};
    }
  }
`
