import React from "react"
import styled from "styled-components"

export function Caption({ children, color }) {
  return <Text color={color}>{children}</Text>
}

const Text = styled.p`
  font-size: 1.3rem;
  color: ${props => props.color || props.theme.color.dark};
  margin-top: 0.5rem;
`
