import React from "react"
import styled from "styled-components"

import Mobility1 from "../../images/icons/mobility1.svg"
import collabration from "../../images/icons/collabration.svg"
import mobility from "../../images/icons/mobility.svg"
import voice from "../../images/icons/voice.svg"

import { ServiceCard } from "../elements"

import ProductMobility from "../../images/product/mobilityProduct.jpg"
import ProductVoice from "../../images/product/voiceProduct.jpg"
import ProductData from "../../images/product/dataProduct.jpg"
import ProductCollabration from "../../images/product/collaborationProduct.jpg"

import { Paragraph } from "../typography"

export function ServicesSection() {
  return (
    <>
      <Cover>
        <Paragraph>
          We’re committed to being your partner for the long haul. Caring for
          your business with ICT solutions and support that are customised to
          your individual needs. It’s our personal approach that makes all the
          difference. Your success is our success.
        </Paragraph>
      </Cover>
      <ServiceCard
        to="/mobility/"
        id="serviceMobility"
        title="Mobility"
        details="Telecomhub procures, provisions, manages and supports your mobility solutions on your behalf, based on your business requirements, for as long as you need."
        src={Mobility1}
        bgImage={ProductMobility}
      />
      <ServiceCard
        to="/voice/"
        order="2"
        id="serviceVoice"
        title="Voice"
        details="Phone lines or channels for your on-site phone system, delivered over the NBN."
        src={voice}
        bgImage={ProductVoice}
      />
      <ServiceCard
        to="/data/"
        id="serviceData"
        title="Data"
        details="Our Broadband NBN Bundle includes unlimited data at nbn50 speeds and a phone line with unlimited local, national and Australian mobile calls."
        src={mobility}
        bgImage={ProductData}
      />
      <ServiceCard
        to="/collabration/"
        order="2"
        id="serviceCollabration"
        title="Collabration"
        details="A full featured phone system that is hosted in the cloud. Make and receive calls via your computer or mobile, or add a smart handset."
        src={collabration}
        bgImage={ProductCollabration}
      />
    </>
  )
}

const Cover = styled.div`
  background-color: ${props => props.theme.color.white};
  text-align: center;
  padding: 5rem 25vw;

  @media only screen and (max-width: 37.5em) {
    padding: 3rem 7vw;
  }
`
