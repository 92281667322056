import React from "react"
import styled from "styled-components"

import plugplay0 from "../../images/data/plugplay0.png"
import reliableinternet0 from "../../images/data/reliableinternet0.png"
import smartbusinessmodem0 from "../../images/data/smartbusinessmodem0.png"
import staticip0 from "../../images/data/staticip0.png"
import supportoptions0 from "../../images/data/supportoptions0.png"
import unlimiteddata0 from "../../images/data/unlimiteddata0.png"

import { ServiceCard } from "../elements"

import ProductMobility from "../../images/product/mobilityProduct.jpg"
import ProductVoice from "../../images/product/voiceProduct.jpg"
import ProductData from "../../images/product/dataProduct.jpg"
import ProductCollabration from "../../images/product/collaborationProduct.jpg"

import { Paragraph } from "../typography"

export function DataSection() {
  return (
    <>
      <Cover>
        <Paragraph>
          {/* We’re committed to being your partner for the long haul. Caring for
          your business with ICT solutions and support that are customised to
          your individual needs. It’s our personal approach that makes all the
          difference. Your success is our success. */}
        </Paragraph>
      </Cover>
      <ServiceCard
        id="serviceMobility"
        title="Fast Reliable NBN"
        details="nbn50 included with the option to upgrade your speed."
        src={reliableinternet0}
        bgImage={ProductMobility}
      />
      <ServiceCard
        order="2"
        id="serviceVoice"
        title="Unlimited Data"
        details="No shaping or excess charges."
        src={unlimiteddata0}
        bgImage={ProductVoice}
      />
      <ServiceCard
        id="serviceData"
        title="Smart Business Modem"
        details="Instant activation with 4G Backup."
        src={smartbusinessmodem0}
        bgImage={ProductData}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Plug & Play"
        details="The Commander Business Modem configures itself when you first plug it in."
        src={plugplay0}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceData"
        title="Support Options"
        details="Reduce NBN downtime with our Priority Network Support packages."
        src={supportoptions0}
        bgImage={ProductData}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Static IP Address"
        details="Included with every connection."
        src={staticip0}
        bgImage={ProductCollabration}
      />
    </>
  )
}

const Cover = styled.div`
  background-color: ${props => props.theme.color.white};
  text-align: center;
  padding: 5rem 25vw;

  @media only screen and (max-width: 37.5em) {
    padding: 3rem 7vw;
  }
`
