import React from "react"
import styled from "styled-components"

import Mobility1 from "../../images/icons/mobility1.svg"
import collabration from "../../images/icons/collabration.svg"
import mobility from "../../images/icons/mobility.svg"
import voice from "../../images/icons/voice.svg"

import { ServiceCard } from "../elements"

import ProductMobility from "../../images/product/mobilityProduct.jpg"
import ProductVoice from "../../images/product/voiceProduct.jpg"
import ProductData from "../../images/product/dataProduct.jpg"
import ProductCollabration from "../../images/product/collaborationProduct.jpg"

import { Paragraph } from "../typography"

export function MobilitySection() {
  return (
    <>
      <Cover>
        <Paragraph>
          {/* We’re committed to being your partner for the long haul. Caring for
          your business with ICT solutions and support that are customised to
          your individual needs. It’s our personal approach that makes all the
          difference. Your success is our success. */}
        </Paragraph>
      </Cover>
      <ServiceCard
        id="serviceMobility"
        title="Optus 4G Plus Network "
        details="Reliable and superfast connectivity to ensure your business needs are satisfied."
        src={Mobility1}
        bgImage={ProductMobility}
      />
      <ServiceCard
        order="2"
        id="serviceVoice"
        title="Bundle for Ultimate Value "
        details="Save $5 per month on Mobile Plans bundled with Office Phones or Internet on the same account."
        src={voice}
        bgImage={ProductVoice}
      />
      <ServiceCard
        id="serviceData"
        title="Shared Data "
        details="More than one mobile? Pool mobile data to share between eligible mobiles on your account."
        src={mobility}
        bgImage={ProductData}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="We make it easy to get a better mobile deal! "
        details="More than one mobile? Pool mobile data to share between eligible mobiles on your account."
        src={collabration}
        bgImage={ProductCollabration}
      />
    </>
  )
}

const Cover = styled.div`
  background-color: ${props => props.theme.color.white};
  text-align: center;
  padding: 5rem 25vw;

  @media only screen and (max-width: 37.5em) {
    padding: 3rem 7vw;
  }
`
