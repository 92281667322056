import React from "react"
import styled from "styled-components"

import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { SpecialTitle } from "../typography"
import { Button, Spacer } from "../elements"
import { ArrowRightIcon } from "../icons/icons"
import { HeaderServicesLinks } from "./headerServicesLinks"

export function HeaderMain() {
  function scrollMenuLink() {
    var elmnt = document.getElementById("headerLinks")
    elmnt.scrollIntoView(false)
  }

  const data = useStaticQuery(graphql`
    query {
      brand1: file(relativePath: { eq: "decorative/mantalking.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const backgroundFluidImageStack = [
    data.brand1.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.35),
      rgba(0, 0, 0, 0.35))`,
  ].reverse()

  return (
    <>
      <Cover>
        <StyledBackground fluid={backgroundFluidImageStack}>
          <ContentCover>
            <SpecialTitle>Telecomhub</SpecialTitle>
            <HeaderTitle color={props => props.theme.color.white}>
              Flexible Phone Systems
            </HeaderTitle>
            <SpecialTitle color={props => props.theme.color.white}>
              for Businesses of All Sizes. SAVE upto 30% over traditional
              business phone systems
            </SpecialTitle>
            <Spacer margin="1rem" />
            <Button
              onClick={scrollMenuLink}
              btncolor={props => props.theme.color.navyblue}
              color={props => props.theme.color.white}
            >
              Solutions <ArrowWhite />
            </Button>
          </ContentCover>
        </StyledBackground>
      </Cover>
      <HeaderServicesLinks onClick={scrollMenuLink} id="headerLinks" />
    </>
  )
}

const Cover = styled.section`
  width: 100%;
  height: 100vh;
`

const ArrowWhite = styled(ArrowRightIcon)`
  color: ${props => props.theme.color.highlight};
`

const StyledBackground = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`

const ContentCover = styled.div`
  position: absolute;
  left: 20vw;
  bottom: 30vh;
  @media only screen and (max-width: 75em) {
    left: 10vw;
    bottom: 25vh;
  }
  @media only screen and (max-width: 56.25em) {
    left: 10vw;
    bottom: 25vh;
  }
  @media only screen and (max-width: 37.5em) {
    left: 5vw;
    bottom: 30vh;
  }
  @media only screen and (min-width: 112.5em) {
    bottom: 40vh;
  }
`

const HeaderTitle = styled.h1`
  font-weight: 700;
  font-size: 6rem;
  letter-spacing: 0.4rem;
  line-height: 1.1;
  color: ${props => props.color || props.color.white};
  text-shadow: 10px 10px 40px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 75em) {
    font-size: 10rem;
  }
  @media only screen and (max-width: 56.25em) {
    font-size: 10rem;
  }
  @media only screen and (max-width: 37.5em) {
    font-size: 6rem;
  }
`
