import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

import { Title, SpecialParagraph } from "../typography"
import { Button } from "../elements/button"
import { ArrowRightIcon } from "../icons/icons"

export function DecorationSection() {
  const data = useStaticQuery(graphql`
    query {
      brand1: file(relativePath: { eq: "decorative/girlcomputer.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.brand1.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.6),
      rgba(1, 1, 1, 0.6))`,
  ].reverse()
  return (
    <Cover>
      <StyledBackground fluid={backgroundFluidImageStack}>
        <ContentCover>
          <Title color={props => props.theme.color.white}>
            We help businesses make a difference by using technology
          </Title>
          <SpecialParagraph>
            Telecomhub increases the business efficiency and value by enabling
            effective decision making and solving the core IT and communication
            problems. Our innovative and tailored IT services keep businesses
            ahead in the competitive landscape.
          </SpecialParagraph>
          <Button to="/about-us/" color={props => props.theme.color.white}>
            Read more <ArrowRightIcon />
          </Button>
        </ContentCover>
      </StyledBackground>
    </Cover>
  )
}

const Cover = styled.section`
  margin: 0.5rem auto 0 auto;
`

const StyledBackground = styled(BackgroundImage)`
  background-position: center;
  background-size: cover;
  padding: 5rem 0;
`

const ContentCover = styled.div`
  width: 55%;
  margin: 0 auto;
  @media only screen and (max-width: 75em) {
    width: 60%;
  }
  @media only screen and (max-width: 56.25em) {
    width: 60%;
  }
  @media only screen and (max-width: 37.5em) {
    width: 90%;
  }
`
