import React from "react"
import styled from "styled-components"

import adaptstobusiness2 from "../../images/voice/adapts-to-business-2.png"
import adaptstobusiness from "../../images/voice/adapts-to-business.png"
import bundlediscount from "../../images/voice/bundle-discount.png"
import businesscontinuity from "../../images/voice/business-continuity.png"
import callforward from "../../images/voice/call-forward.png"
import costeffective from "../../images/voice/cost-effective.png"
import deliverednbn from "../../images/voice/delivered-nbn.png"
import fasterlead from "../../images/voice/faster-lead.png"
import handycallingfeatures from "../../images/voice/handy-calling-features.png"
import linehunt from "../../images/voice/line-hunt.png"
import overthetop from "../../images/voice/over-the-top.png"
import shorterleadtimes from "../../images/voice/shorter-lead-times.png"

import { ServiceCard } from "../elements"

import ProductMobility from "../../images/product/mobilityProduct.jpg"
import ProductVoice from "../../images/product/voiceProduct.jpg"
import ProductData from "../../images/product/dataProduct.jpg"
import ProductCollabration from "../../images/product/collaborationProduct.jpg"

import { Paragraph } from "../typography"

export function VoiceSection() {
  return (
    <>
      <Cover>
        <Paragraph>
          {/* We’re committed to being your partner for the long haul. Caring for
          your business with ICT solutions and support that are customised to
          your individual needs. It’s our personal approach that makes all the
          difference. Your success is our success. */}
        </Paragraph>
      </Cover>
      <ServiceCard
        id="serviceMobility"
        title="Delivered Over the NBN"
        details="NBN Business Line uses the latest technology and works over your NBN connection."
        src={deliverednbn}
        bgImage={ProductMobility}
      />
      <ServiceCard
        order="2"
        id="serviceVoice"
        title="Adapts to your business"
        details="Add and remove lines as your business needs them."
        src={adaptstobusiness}
        bgImage={ProductVoice}
      />
      <ServiceCard
        id="serviceData"
        title="Handy Calling Features"
        details="Plenty of great features like Caller ID, Call Transfer, Call Waiting and more."
        src={handycallingfeatures}
        bgImage={ProductData}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Call Forwarding"
        details="Forward calls to your mobile or another site when you're on the phone or out of the office."
        src={callforward}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Line Hunt"
        details="Set up a basic line hunt to share calls between your lines. Charges apply."
        src={linehunt}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Bundle Discount"
        details="Get your first line for $0 when you bundle with a Business One NBN plan."
        src={bundlediscount}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Adapts to your business"
        details="Add and remove lines as your business needs them."
        src={adaptstobusiness2}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Over The Top"
        details="Use Commander SIP with any Internet provider."
        src={overthetop}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Cost Effective"
        details="Add single channels after your minimum of 2."
        src={costeffective}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Business Continuity"
        details="Calls can be redirected to another number if your services aren't reachable."
        src={businesscontinuity}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        id="serviceCollabration"
        title="Discount for Contract"
        details="Take this plan over 5 years and receive a discount."
        src={bundlediscount}
        bgImage={ProductCollabration}
      />
      <ServiceCard
        order="2"
        id="serviceCollabration"
        title="Shorter Lead-times"
        details="Connects quicker than traditional ISDN and PSTN lines."
        src={fasterlead}
        bgImage={ProductCollabration}
      />
    </>
  )
}

const Cover = styled.div`
  background-color: ${props => props.theme.color.white};
  text-align: center;
  padding: 5rem 25vw;

  @media only screen and (max-width: 37.5em) {
    padding: 3rem 7vw;
  }
`
