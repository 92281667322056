import React from "react"
import styled from "styled-components"

export function SpeacialMediumTitle({ children, color }) {
  return <MediumTitle color={color}>{children}</MediumTitle>
}

const MediumTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${props => props.color || props.theme.color.white};
  margin-top: 0.5rem;
`


