import React, { useState } from "react"
import styled from "styled-components"
import { PhoneCall, Mail } from "react-feather"
import { Link } from "gatsby"

export function CallToAction() {
  const [options, setOptions] = useState("hidden")

  function onHoverFunc() {
    if (options === "hidden") {
      setOptions("visible")
    } else {
      setOptions("hidden")
    }
  }

  return (
    <CallCover onClick={onHoverFunc}>
      <CallIcon />
      <MailIcon />
      <PhoneOptions display={options}>
        <Option1 href="tel:+61394577300">Melbourne 03 9457 7300</Option1>
      </PhoneOptions>
      <DesktopOptions display={options}>
        <Option1 href="mailto:info@telecomhub.com.au">
          info@telecomhub.com.au
        </Option1>
      </DesktopOptions>
    </CallCover>
  )
}

const CallCover = styled.div`
  text-decoration: none;
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.highlight};
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  box-shadow: ${({ theme }) => theme.shadow.btnshadow};
`
const CallIcon = styled(PhoneCall)`
  width: 3.1rem;
  height: 3.1rem;
  color: ${({ theme }) => theme.color.white};
  display: none;

  @media only screen and (max-width: 37.5em) {
    display: flex;
  }
`
const MailIcon = styled(Mail)`
  width: 3.1rem;
  height: 3.1rem;
  color: ${({ theme }) => theme.color.white};

  @media only screen and (max-width: 37.5em) {
    display: none;
  }
`

const PhoneOptions = styled.div`
  right: 1rem;
  bottom: 10rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  display: none;
  visibility: ${props => props.display};
  @media only screen and (max-width: 37.5em) {
    display: flex;
  }
`
const DesktopOptions = styled.div`
  right: 1rem;
  bottom: 10rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  visibility: ${props => props.display};
  @media only screen and (max-width: 37.5em) {
    display: none;
  }
`
const Option1 = styled(Link)`
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  background-color: ${({ theme }) => theme.color.highlight};
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.white};
  margin: 0.5rem 0;
`
const Option2 = styled(Option1)``
